import React, { Component } from 'react'

export class SectionHeader extends Component {
    render() {
        const { title, description } = this.props;
        return (
            <div className='flex flex-col gap-[16px] container'>
                <div className='flex justify-center'>
                    <h2 className='font-[600] text-[32px] leading-[40px] text-center text-[#000]' dangerouslySetInnerHTML={{ __html: title }}></h2>
                </div>

                <div className='p-[8px_16px]'>
                    <p className='font-[600] text-[16px] leading-[24px] text-[#8D8D8D] text-center' dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
            </div>

        )
    }
}

export default SectionHeader