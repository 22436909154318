import React, { Component } from 'react'
import { connect } from 'react-redux';

export class ChangeLanguage extends Component {
    componentDidMount() {
        if (!localStorage.getItem('languageValue')) {
            localStorage.setItem('languageValue', '1');
            localStorage.setItem('langCode', 'az');
        }

        const langCode = localStorage.getItem('langCode') || 'az';
        const currentPath = window.location.pathname;

        if (!currentPath.startsWith(`/${langCode}`)) {
            window.location.pathname = `/${langCode}${currentPath}`;
        }
    }

    handleLanguageChange = (language) => {
        const languageValue = language === 'AZ' ? '1' : '2';
        const langCode = language === 'AZ' ? 'az' : 'ru';

        localStorage.setItem('languageValue', languageValue);
        localStorage.setItem('langCode', langCode);

        const currentPath = window.location.pathname.replace(/^\/(az|ru)/, `/${langCode}`);
        window.location.pathname = currentPath;
    };
    render() {
        const { contentData } = this.props;
        return (
            <div className='gap-[16px] flex max-[1010px]:items-center justify-center'>
                <button onClick={() => this.handleLanguageChange('AZ')}
                    className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] max-[900px]:p-[8px_32px] max-[900px]:border-[1px] max-[900px]:border-[#000] max-[900px]:rounded-[4px] max-[900px]:text-[#000]'
                    dangerouslySetInnerHTML={{ __html: contentData?.language_az }}>
                </button>
                <button onClick={() => this.handleLanguageChange('RU')}
                    className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] max-[900px]:p-[8px_32px] max-[900px]:border-[1px] max-[900px]:border-[#000] max-[900px]:rounded-[4px] max-[900px]:text-[#000]'
                    dangerouslySetInnerHTML={{ __html: contentData?.language_ru }}>
                </button>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage)