import React, { Component } from 'react'
import TopArrowSvg from '../icons/TopArrowSvg'
import MenuIconSvg from '../icons/MenuIconSvg';

export class WindowTop0 extends Component {
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    render() {
        return (
            <button onClick={this.scrollToTop} className='fixed bottom-[20px] left-[20px] z-[989] p-[16px] bg-[#666666] rounded-[50%] hidden max-[1010px]:block'>
                {/* <TopArrowSvg /> */}
                <MenuIconSvg />
            </button>
        )
    }
}

export default WindowTop0