import React, { Component } from 'react'
import { connect } from 'react-redux'
import logo from '../images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import ChangeLanguage from './ChangeLanguage'
import InstagramSvg20 from '../icons/InstagramSvg20';
import FacebookSvg20 from '../icons/FacebookSvg20';
import WhatsappSvg20 from '../icons/WhatsappSvg20';
import InstagramSvg2 from '../icons/InstagramSvg2';
import WhatsappIcons2 from '../icons/WhatsappIcons2';
import FacebookSvg2 from '../icons/FacebookSvg2';

import TopHamburgerSvg from '../icons/TopHamburgerSvg'
import ButtonHamburgerSvg from '../icons/ButtonHamburgerSvg'

export class Header extends Component {
    state = {
        inpRef: React.createRef()
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("click", this.handleClickOutside);
    }

    handleScroll = () => {
        const nav = document.querySelector('.navbar');
        if (window.scrollY >= 50) {
            nav.style.position = 'fixed';
            nav.style.top = '0px';
        } else {
            nav.style.position = 'inherit';
        }
    };

    handleClickOutside = (event) => {
        const { inpRef } = this.state;
        const menu = document.querySelector('.navlist'); // Menü elemanını seçiyoruz
        const label = document.querySelector('.lbl-resp'); // Menü açma düğmesini seçiyoruz

        // Sabit butona tıklanırsa menüyü kapatma
        const isFixedButton = event.target.closest('#fixed-button');

        if (
            inpRef.current && // Input referansı varsa
            !inpRef.current.contains(event.target) && // Input dışında tıklama
            !menu.contains(event.target) && // Menü dışında tıklama
            !label.contains(event.target) && // Menü açma düğmesi dışında tıklama
            !isFixedButton // Sabit buton dışında tıklama
        ) {
            this.closeNav(inpRef);
        }
    };

    handleMenuToggle = () => {
        const { inpRef } = this.state;
        if (inpRef?.current.checked) {
            document.body.classList.add('no-scroll'); // Body'ye sınıf ekle
            document.documentElement.classList.add('no-scroll'); // HTML'ye sınıf ekle
        } else {
            document.body.classList.remove('no-scroll'); // Body'den sınıfı kaldır
            document.documentElement.classList.remove('no-scroll'); // HTML'den sınıfı kaldır
        }
    };

    closeNav = (inpRef) => {
        if (inpRef?.current) {
            inpRef.current.checked = false;
            document.body.classList.remove('no-scroll'); // Body'den sınıfı kaldır
            document.documentElement.classList.remove('no-scroll'); // HTML'den sınıfı kaldır
        }
    };



    getLangCode = () => {
        const langCode = localStorage.getItem('langCode');
        return langCode === 'az' ? '/az' : '/ru';
    };

    render() {
        const { inpRef } = this.state;
        const { contentData } = this.props;

        const langCodeId = this.getLangCode();
        return (
            <nav className="p-[28px_68px_16px_68px] max-[900px]:p-[20px_26px_10px_26px]  w-full bg-[#fff] navbar z-[980]">

                <div className='flex justify-between items-center w-full menu'>
                    <NavLink to={`${langCodeId}/`} className=" w-[32%] max-[500px]:w-[70%]  ">
                        <img className="w-[223px] h-[56px] max-[900px]:w-[156px] max-[900px]:h-[38px]" src={logo} alt="Logo" />
                    </NavLink>

                    <input ref={inpRef} type="checkbox" id="onclick" className="inp_check" onChange={this.handleMenuToggle} />

                    <label htmlFor="onclick" className="lbl-resp">
                        <ButtonHamburgerSvg />
                    </label>



                    <ul className='flex items-center gap-[32px] max-[900px]:gap-[4px]  w-[30%] max-[900px]:w-full navlist  '>

                        <div className='pt-[20px] w-full items-center bg-[#fff] hidden max-[900px]:flex justify-between'>
                            <img className="w-[156px] h-[38px] " src={logo} alt="Logo" />

                            <div onClick={() => this.closeNav(this.state.inpRef)}>
                                <TopHamburgerSvg />
                            </div>
                        </div>


                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] min-w-[80px] max-[900px]:w-full max-[900px]:text-center
                         max-[900px]:text-[#000] max-[900px]:p-[80px_18px_0px_18px]    '>
                            <NavLink
                                onClick={() => this.closeNav(this.state.inpRef)}
                                to={`${langCodeId}/`}
                                className=' max-[900px]:w-full max-[900px]:border-b-[1px]  max-[900px]:p-[16px_36px]  max-[900px]:border-[#000] w-full ' dangerouslySetInnerHTML={{ __html: contentData?.nav_home_page }}>

                            </NavLink>
                        </li>
                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] max-[900px]:w-full max-[900px]:text-center
                         max-[900px]:text-[#000] max-[900px]:p-[0px_18px]   '>
                            <NavLink
                                onClick={() => this.closeNav(this.state.inpRef)}

                                to={`${langCodeId}/menu`}
                                className=' max-[900px]:w-full max-[900px]:border-b-[1px]  max-[900px]:p-[16px_36px]  max-[900px]:border-[#000] w-full ' dangerouslySetInnerHTML={{ __html: contentData?.nav_menu }} >

                            </NavLink>
                        </li>
                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D]  max-[900px]:w-full max-[900px]:text-center
                         max-[900px]:text-[#000] max-[900px]:p-[0px_18px]  '>
                            <NavLink
                                onClick={() => this.closeNav(this.state.inpRef)}
                                to={`${langCodeId}/company`}
                                className=' max-[900px]:w-full max-[900px]:border-b-[1px]  max-[900px]:p-[16px_36px]  max-[900px]:border-[#000] w-full ' dangerouslySetInnerHTML={{ __html: contentData?.nav_contact }} >
                            </NavLink>
                        </li>

                        <div className='hidden max-[900px]:flex pt-[52px]'>
                            <ChangeLanguage />
                        </div>

                        <div className='hidden max-[900px]:flex max-[900px]:gap-[32px] pt-[40px]'>
                            <Link onClick={() => this.setState({ isSidebarOpen: false })}
                                to="https://www.instagram.com/maqqash.baku/" target='__blank'>
                                <InstagramSvg2 fill="#000" />
                            </Link>
                            <Link onClick={() => this.setState({ isSidebarOpen: false })}
                                to="https://www.facebook.com/maqqash.baku" target='__blank'>
                                <FacebookSvg2 fill="#000" />
                            </Link>
                            <Link onClick={() => this.setState({ isSidebarOpen: false })}
                                to="https://wa.me/+994504905905" target='__blank'>
                                <WhatsappIcons2 fill="#000" />
                            </Link>
                        </div>

                        <div className='hidden max-[900px]:flex flex-col items-center gap-[16px] pt-[40px]'>
                            <p className='font-[700] text-[16px] leading-[24px] text-[#000]' dangerouslySetInnerHTML={{ __html: contentData?.working_house }}></p>
                            <p className='font-[400] text-[16px] leading-[24px] text-[#000]' dangerouslySetInnerHTML={{ __html: contentData?.clock }}></p>
                        </div>
                    </ul>

                    <div className='flex items-center gap-[72px] max-[900px]:hidden'>

                        <div className='flex items-center gap-[32px]'>
                            <Link to="https://www.instagram.com/maqqash.baku/" target='__blank'>
                                <InstagramSvg20 />
                            </Link>
                            <Link to="https://www.facebook.com/maqqash.baku" target='__blank'>
                                <FacebookSvg20 />
                            </Link>
                            <Link to="https://wa.me/+994504905905" target='__blank'>
                                <WhatsappSvg20 />
                            </Link>
                        </div>

                        <ChangeLanguage />
                    </div>

                </div>

            </nav >
        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)