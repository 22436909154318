import React, { Component } from 'react'
import video_logo from '../components/images/video_logo.png'
import SectionHeader from '../components/SectionHeader'
import slider_photo from '../components/images/slider_image.png'
import MnPhotoSlider from '../components/MnPhotoSlider';
import ProjectMnSections from '../components/ProjectMnSections';
import { getVideo } from '../actions/MainAction';
import { connect } from 'react-redux';
import CallButton from '../components/cards/CallButton';
import MenuButton from '../components/cards/MenuButton';



export class MainPage extends Component {

    componentDidMount() {
        this.props.getVideo()
        window.scrollTo(0, 0);
    }

    handleVideoEnd = (e) => {
        e.target.play();
    }

    render() {
        const { contentData, videoData } = this.props;
        // console.log(contentData);


        return (
            <section >
                <section className="relative  h-[400px] w-full flex items-center justify-center">
                    {videoData?.map((data, i) => {
                        return (
                            <video
                                key={i}
                                src={data?.video_full_url}
                                className="absolute top-0 left-0 w-full h-full object-cover"
                                autoPlay
                                loop
                                muted
                                playsInline
                                onEnded={this.handleVideoEnd}
                            ></video>
                        )
                    })

                    }

                    <div className='relative z-10 w-full h-full flex justify-center items-center bg-[#00000031]'>
                        <img src={video_logo} className='w-[224px] h-[184px]' />
                    </div>
                </section>



                <section className='pt-[64px] '>
                    <SectionHeader title={contentData?.caption_1}
                        description={contentData?.caption_1_desc}
                    />

                    {/* <div dangerouslySetInnerHTML={{ __html: contentData?.test }}>

                    </div> */}
                    <MnPhotoSlider />

                </section>



                <section className='pt-[87px]'>
                    <SectionHeader title={contentData?.caption_2}
                        description={contentData?.caption_2_desc}
                    />


                </section>
                <ProjectMnSections />

                <MenuButton />
                <CallButton />


            </section >
        )
    }
}


const mapStateToProps = (state) => ({
    videoData: state.Data.videoData,
    contentData: state.Data.contentData,
})

const mapDispatchToProps = { getVideo }

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)